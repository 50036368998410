import { defineNuxtPlugin } from '#app';

const numberFormat = defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide('numberFormat', (amount: any, decimalCount = 0, decimal = ',', thousands = ' ') => {
    // if (decimalCount === -1) {
    //   decimalCount = (String(amount).split('.')?.[1] || '').length;
    //   if (decimalCount > 3) {
    //     decimalCount = 3
    //   }
    // }

    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    const i: any = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    const j: any = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '');
  });
});

export default numberFormat;
