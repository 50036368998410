interface IUseMobileSearch {
  isOpen: Ref<boolean>;

  changeOpen: (_val: boolean) => void;
}

export const useMobileSearch = (): IUseMobileSearch => {

  const isOpen: Ref<boolean> = useState<boolean>('isOpenMobileSearch', () => false);
  const changeOpen = (_val: boolean) => {
    isOpen.value = _val;

    if (_val) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }


  return {
    isOpen,

    changeOpen
  }
}
