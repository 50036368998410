import type { Ref } from 'vue';

interface IUserManagerInfo {
	id: number | string;
	name: string;
	email: string;
}

interface IUseSitisUserManager {
	initUserManager: () => void;
	userManagerInfo: Ref<IUserManagerInfo | undefined>
}

export default function useSitisUserManager (): IUseSitisUserManager {
  const userManagerInfo = useState<IUserManagerInfo | undefined>('userManagerInfo', () => undefined);

  const initUserManager = () => {
    const adminData: any = useCookie('adminData');
    if (!adminData.value) {
      return;
    }

    userManagerInfo.value = adminData;
  };

  return {
    userManagerInfo,
    initUserManager
  };
};
