<template>
  <div class="error-content">
    <div class="container">
      <div class="error-content__content">
        <div class="error-content__tag">Ошибка {{ error?.statusCode || 404 }}</div>
        <h1
          v-html="mainMessage"
          class="error-content__title"
        />
        <div class="error-content__caption h5">
          Зато есть другие интересные страницы
        </div>
        <div class="error-content__button">
          <nuxt-link
            to="/"
            class="btn btn-primary"
          >
            На главную страницу
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ComputedRef } from 'vue';

const props = defineProps({
  error: {
    type: Object as any,
    default: () => {}
  }
});

const mainMessage: ComputedRef<string> = computed(() => {
  let message = props?.error?.message;
  if (String(message || '').toLowerCase() === 'page not found') {
    message = ""
  }

  return message || "Такой страницы<br />у нас нет"
})
</script>

<style scoped lang="scss">
.error-content {
	padding: 80px 0;
	box-sizing: border-box;
}

.error-content__content {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.error-content__tag {
	padding: 12px 24px;
	border-radius: 10px;
	background: #ECE2C9;
	box-sizing: border-box;

	color: #252525;
	font-size: 12px;
	font-weight: 600;
	line-height: 100%;
}

.error-content__title {
	margin-top: 32px;
	color: #000000;
	text-align: center;
}

.error-content__caption {
	margin-top: 16px;
	color: #989898;
	text-align: center;
}

.error-content__button {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 40px;

	.btn {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 60px;
		border-radius: 16px;
		width: 100vw;
		max-width: 386px;

		font-size: 16px;
		font-weight: 600;
		line-height: 100%;
	}
}
</style>
