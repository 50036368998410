import type { Ref } from 'vue';
import type { IProduct } from '#sitis/internal/controllers/products/models/Product';

interface IUseCreateOrder3dProject {
  isCreateOrder3dProjectOpen: Ref<boolean>;
  closeCreateOrder3dProjectOpen: () => void;
  openCreateOrder3dProjectOpen: () => void;
}

export const useCreateOrder3dProject = (): IUseCreateOrder3dProject => {
  const isCreateOrder3dProjectOpen = useState<boolean>('isCreateOrder3dProjectOpen', () => false);

  const closeCreateOrder3dProjectOpen = () => {
    document.body.style.overflow = "";
    isCreateOrder3dProjectOpen.value = false;
  };

  const openCreateOrder3dProjectOpen = () => {
    document.body.style.overflow = "hidden";
    isCreateOrder3dProjectOpen.value = true;
  };

  return {
    isCreateOrder3dProjectOpen,
    closeCreateOrder3dProjectOpen,
    openCreateOrder3dProjectOpen
  };
};
