<template>
  <div>
    <NuxtLayout>
      <main class="main">
        <ErrorContent
          :error="error"
        />
      </main>

      <DialogCitySelect v-if="isSelectCityPopupOpen" />
      <DialogLoading v-if="isDialogLoading" />
      <DialogCreateReview v-if="isCreateReviewModalOpen" />
      <DialogNotification v-if="isOpenNotificationModal" />
    </NuxtLayout>
  </div>
</template>

<script lang="ts" setup>
import { catalogStore } from '#sitis/stores/modules/catalog-store';
import { appConfigStore as useAppConfigStore } from '#sitis/stores/modules/app-store';
import { cmsContentStore } from '#sitis/stores/modules/cms-content-store';
import { useUserCity } from '~/composables/useUserCity';
import { useCommonStore } from '~/store/common';
import { useCreateReviewModal } from '~/composables/useCreateReviewModal';
import { useNotificationModal } from '~/composables/useNotificationModal';
import ErrorContent from '~/components/error/ErrorContent.vue';

const DialogLoading = defineAsyncComponent(() => import('~/components/common/DialogLoading.vue'));
const DialogCitySelect = defineAsyncComponent(() => import('~/components/common/DialogCitySelect.vue'));
const DialogCreateReview = defineAsyncComponent(() => import('~/components/common/DialogCreateReview.vue'));
const DialogNotification = defineAsyncComponent(() => import('~/components/common/DialogNotification.vue'));

const props = defineProps({
  error: {
    type: Object as any
  }
});
const { isSelectCityPopupOpen } = useUserCity();
const { isCreateReviewModalOpen } = useCreateReviewModal();
const { isOpenNotificationModal } = useNotificationModal();

const commonStore = useCommonStore();

const isDialogLoading = computed(() => commonStore._loading);
const cmsStore = cmsContentStore();
const storeCatalog = catalogStore();
const appConfigStore = useAppConfigStore();

onServerPrefetch(async () => {
  await cmsStore.getMenu();
  await appConfigStore.getConfig();
  await storeCatalog.getCatalog();
});

const eventScroll = () => {
  commonStore.changeHeaderFixed(Boolean(window.scrollY > 100));
};
useHead(() => {
  const head: any = {
    title: '',
    meta: [],
    link: []
  };
  const favicon = appConfigStore?.$state?.config?.favicon || null;
  if (favicon) {
    head.link.push({ rel: 'icon', type: 'image/x-icon', href: favicon });
  }

  return head;
});

onMounted(() => {
  window.addEventListener('scroll', eventScroll);
});
</script>

<style lang="scss" scoped>
.root-layout {
	display: flex;
	flex-direction: column;
}

.root-layout__header {
}

.root-layout__body {
	min-height: calc(100vh - 180px);
}

.root-layout__footer {
}
</style>
