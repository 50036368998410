import { cartStore } from '#sitis/stores/modules/cart-store';

export default defineNuxtPlugin(async (nuxtApp) => {
  const { isLoggedIn, getUser } = useSitisAuth();

  const storeCart = cartStore();
  const { getWishList } = useSitisWish();

  if (!isLoggedIn.value) {
    await getUser().catch((err) => {
      console.log('load user plugin:', err.response?._data?.message);
    });
  }

  await storeCart.getCartItems();
  await getWishList({ extensions: ['short_characteristics'] });
});
