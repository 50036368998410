import { useNuxtApp } from '#imports';
import type { Ref } from '#imports';

export type CityListCityType = {
	id: number
	name: string
	region_id: number
	has_data: boolean
	data_id: number
	sub_domain: string
}

type CityListRegion = {
	cms_data?: string;
	id: number;
	name: string;
}

type CityListType = {
	favorites: CityListCityType[];
	cities: CityListCityType[];
	regions: CityListRegion[];
}
type selectCityStateType = {
	extraPath?: string;
	isNeedReloadPage?: boolean;
	cityName?: string;
	cityId?: number;
}

type UseUserCityType = {
	citiesList: Ref<CityListType | null | undefined>;
	isCitiLoading: Ref<boolean>;
	isSelectCityPopupOpen: Ref<boolean>;
	changeSelectPopupOpen: (isOpen: boolean, options?: selectCityStateType) => void;
	getAllCitiesList: () => Promise<CityListType | null | undefined>;
	setCityUser: (cityId: number, cityName?: string) => Promise<void>;
	selectCityState: Ref<selectCityStateType>;
}

export function useUserCity (): UseUserCityType {
  const { $api, $toast } = useNuxtApp();
  const isCitiLoading = useState<boolean>('isCitiLoading', () => false);
  const isSelectCityPopupOpen = useState<boolean>('isSelectCityPopupOpen', () => false);
  const citiesList = useState<CityListType | null | undefined>('citiesAllList', () => null);
  const selectCityState = useState<selectCityStateType>('selectCityState', () => ({}));

  const getAllCitiesList = async (): Promise<CityListType | null | undefined> => {
    if (citiesList.value) {
      return;
    }

    isCitiLoading.value = true;

    const res = await $api.agent.get<CityListType | null>('cities')
      .then((res) => res._data)
      .catch(() => {
        console.log('error while response all cities');
        return null;
      });

    isCitiLoading.value = false;
    if (res) {
      citiesList.value = res;
    }

    return res;
  };

  const setCityUser = async (cityId: number, cityName?: string) => {
    // commonStore.changeLoading(true);
    selectCityState.value.cityId = cityId;
    selectCityState.value.cityName = cityName;

    if (selectCityState.value.isNeedReloadPage === false) {
      changeSelectPopupOpen(false);
      return;
    }

    const response = await $api.agent.put('/cities/current', {
      body: {
        city_id: cityId
      }
    }).then((res) => ({
      ...res._data,
      'x-redirect': res?.headers?.get('x-redirect')
    })).catch(() => ({ error: true }));
    if (response.error) {
      $toast.error('Ошибка установки города, повторите попытку позднее.');
      return;
    }

    window.location.reload();
  };

  const changeSelectPopupOpen = (isOpen: boolean, options?: selectCityStateType) => {
    isSelectCityPopupOpen.value = isOpen;

    if (isOpen) {
      selectCityState.value = {};
    }
    if (options) {
      selectCityState.value = { ...options };
    }

    isOpen ? document.body.style.overflow = 'hidden' : document.body.style.overflow = '';
  };

  return {
    selectCityState,
    citiesList,
    isCitiLoading,
    isSelectCityPopupOpen,

    changeSelectPopupOpen,
    setCityUser,
    getAllCitiesList
  };
}
