import type { Ref } from 'vue';

type IMainInfo = {
  title: string,
  message: string
};
type IAdditionalInfo = {

};
interface IUseNotificationModal {
  isOpenNotificationModal: Ref<boolean>,
  infoNotificationModal: Ref<IMainInfo | undefined>,

  openNotificationModal: (mainInfo: IMainInfo, additionalInfo?: IAdditionalInfo) => void;
  closeNotificationModal: () => void;
}

export const useNotificationModal = ():IUseNotificationModal => {
  const isOpenNotificationModal = useState<boolean>('isOpenNotificationModal', () => false);
  const infoNotificationModal = useState<IMainInfo | undefined>('infoNotificationModal', () => undefined);

  const openNotificationModal = (mainInfo: IMainInfo, additionalInfo?: IAdditionalInfo) => {
    infoNotificationModal.value = mainInfo;
    isOpenNotificationModal.value = true;
  }
  const closeNotificationModal = () => {
    infoNotificationModal.value = undefined;
    isOpenNotificationModal.value = false;
  }

  return {
    isOpenNotificationModal,
    infoNotificationModal,

    openNotificationModal,
    closeNotificationModal
  };
};
