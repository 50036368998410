import type { Ref } from 'vue';
import type { IProduct } from '#sitis/internal/controllers/products/models/Product';

interface IUseFastOrderModal {
  product: Ref<IProduct | undefined>,
  isCreateReviewModalOpen: Ref<boolean>;
  openCreateReviewModal: (_product: IProduct) => void;
  closeCreateReviewModal: () => void;
}

export const useCreateReviewModal = (): IUseFastOrderModal => {
  const product = useState<IProduct | undefined>('product', () => undefined);
  const isCreateReviewModalOpen = useState<boolean>('isCreateReviewModalOpen', () => false);

  const closeCreateReviewModal = () => {
    document.body.style.overflow = "";
    product.value = undefined;
    isCreateReviewModalOpen.value = false;
  };

  const openCreateReviewModal = (_product: IProduct) => {
    document.body.style.overflow = "hidden";
    product.value = _product;
    isCreateReviewModalOpen.value = true;
  };

  return {
    product,
    isCreateReviewModalOpen,
    closeCreateReviewModal,
    openCreateReviewModal
  };
};
