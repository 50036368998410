interface IUseMobileSidebar {
  isOpen: Ref<boolean>;

  changeOpen: (_val: boolean) => void;
}

export const useMobileSidebar = (): IUseMobileSidebar => {

  const isOpen: Ref<boolean> = useState<boolean>('isOpenMobileSidebar', () => false);
  const changeOpen = (_val: boolean) => {
    isOpen.value = _val;
  }


  return {
    isOpen,

    changeOpen
  }
}
