// import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin({
  name: 'sentry',
  setup (nuxtApp) {
    // const router = useRouter();
    // const { public: { sentry, baseApiUrl } } = useRuntimeConfig();
    //
    // if (!sentry.dsn) {
    //   return;
    // }
    // console.log('sentry working in:', sentry.environment);
    // Sentry.init({
    //   app: nuxtApp.vueApp,
    //   dsn: sentry.dsn,
    //   environment: sentry.environment,
    //
    //   integrations: [
    //     Sentry.browserTracingIntegration({ router }),
    //     Sentry.replayIntegration()
    //   ],
    //
    //   tracesSampleRate: 1.0, // Change in prod
    //
    //   // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    //   tracePropagationTargets: ['localhost', baseApiUrl],
    //
    //   replaysSessionSampleRate: 0.1, // Change in prod
    //   replaysOnErrorSampleRate: 1.0 // Change in prod if necessary
    // });
  }
});
