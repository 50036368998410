<template>
  <section>
    <NuxtLoadingIndicator color="#FFC115" />
    <NuxtLayout>
      <main class="main">
        <NuxtPage />
      </main>
    </NuxtLayout>

    <MobileSidebar v-if="isOpen" />
    <DialogLoading v-if="isDialogLoading" />
    <DialogMobileSearch v-if="isOpenMobileSearch" />
    <DialogCitySelect v-if="isSelectCityPopupOpen" />
    <DialogCreateReview v-if="isCreateReviewModalOpen" />
    <DialogNotification v-if="isOpenNotificationModal" />
    <NotificationManager v-if="userManagerInfo?.id" />
    <DialogOrder3dProject v-if="isCreateOrder3dProjectOpen" />

    <GalleryComponent v-if="isGalleryOpen && $viewport.isGreaterOrEquals('sm')" />
    <GalleryMobileComponent v-if="isGalleryOpen && !$viewport.isGreaterOrEquals('sm')" />

    <div v-html="footerCounter" />
    <Link rel="canonical" :href="linkCanonical"/>
  </section>
</template>

<script lang="ts" setup>
import { catalogStore } from '#sitis/stores/modules/catalog-store';
import { appConfigStore as useAppConfigStore } from '#sitis/stores/modules/app-store';
import { cmsContentStore } from '#sitis/stores/modules/cms-content-store';
import { useUserCity } from '~/composables/useUserCity';
import { useCommonStore } from '~/store/common';
import { useCreateReviewModal } from '~/composables/useCreateReviewModal';
import { useNotificationModal } from '~/composables/useNotificationModal';
import { useMobileSidebar } from '~/composables/useMobileSidebar';
import { useMobileSearch } from '~/composables/useMobileSearch';
import { useCreateOrder3dProject } from '~/composables/useCreateOrder3dProject';
import useGalleryComponent from '~/composables/useGallery';
import useSitisUserManager from '~/composables/useSitisUserManager';

const GalleryComponent = defineAsyncComponent(() => import('~/components/widget/GalleryComponent.vue'));
const GalleryMobileComponent = defineAsyncComponent(() => import('~/components/widget/GalleryMobileComponent.vue'));
const DialogLoading = defineAsyncComponent(() => import('~/components/common/DialogLoading.vue'));
const MobileSidebar = defineAsyncComponent(() => import('~/components/header/MobileSidebar.vue'));
const DialogCitySelect = defineAsyncComponent(() => import('~/components/common/DialogCitySelect.vue'));
const DialogMobileSearch = defineAsyncComponent(() => import('~/components/common/DialogMobileSearch.vue'));
const DialogCreateReview = defineAsyncComponent(() => import('~/components/common/DialogCreateReview.vue'));
const DialogNotification = defineAsyncComponent(() => import('~/components/common/DialogNotification.vue'));
const NotificationManager = defineAsyncComponent(() => import('~/components/common/NotificationManager.vue'));
const DialogOrder3dProject = defineAsyncComponent(() => import('~/components/common/DialogOrder3dProject.vue'));

const {
  $vi
} = useNuxtApp();
const { isOpen } = useMobileSidebar();
const { isSelectCityPopupOpen } = useUserCity();
const { isCreateReviewModalOpen } = useCreateReviewModal();
const { isOpenNotificationModal } = useNotificationModal();
const { isOpen: isOpenMobileSearch } = useMobileSearch();
const { isCreateOrder3dProjectOpen } = useCreateOrder3dProject();
const { isGalleryOpen } = useGalleryComponent();
const { userManagerInfo, initUserManager } = useSitisUserManager();

const commonStore = useCommonStore();

const isDialogLoading = computed(() => commonStore._loading);
const cmsStore = cmsContentStore();
const storeCatalog = catalogStore();
const appConfigStore = useAppConfigStore();

onServerPrefetch(async () => {
  await cmsStore.getMenu();
  await appConfigStore.getConfig();
  await storeCatalog.getCatalog();
});

const eventScroll = () => {
  commonStore.changeHeaderFixed(Boolean(window.scrollY > 100));
};

onMounted(() => {
  const oldBuildId = useCookie('save-build-version');
  const currentBuildId = useNuxtApp()?._appConfig?.nuxt?.buildId;

  initUserManager();
  window.addEventListener('scroll', eventScroll);
  window.addEventListener('vite:preloadError', (event) => {
    if (oldBuildId.value !== currentBuildId) {
      oldBuildId.value = currentBuildId;
      window.location.reload();
    }
  });



  setUserAbTesting();
  // setYClid();

  addEventListener("contextmenu", eventContextMenu);
});

useHead(() => {
  const head: any = {
    title: '',
    meta: [],
    link: []
  };
  const favicon = appConfigStore?.$state?.config?.favicon || null;

  if (favicon) {
    head.link.push({ rel: 'icon', type: 'image/x-icon', href: favicon });
  }

  return head;
});

// Счетчики для футера
const footerCounter: any = computed(() => {
  return appConfigStore?.$state?.config?.counters?.footer || '';
});

const linkCanonical: ComputedRef<string> = computed(() => {
  const event = useRequestEvent();
  const host = process.server ? event?.node.req.headers.host || '' : window.location.host;
  const [path] = useRoute().fullPath.split('?');
  return `https://${host}${path}`
});


const setYClid = () => {
  const yandexMetrika: any = useNuxtApp()?.payload?.config?.public?.yandexMetrika?.id || null;
  if (!yandexMetrika) {
    return;
  }

  const _window: any = window;
  if (!_window.ym) {
    return null
  }

  // _window.ym(yandexMetrika, "init", () => {
  //   console.log('asdasd')
  // });
  // console.log('asd:', yandexMetrika)

  const clientId = _window?.Ya?._metrika?.counter.getClientID();
  if (!clientId) {
    return
  }

  const yclid: any = useCookie('yclid');
  yclid.value = clientId;
}

const setUserAbTesting = async () => {

  // Кнопка у карточки коллекции (ВЫБРАЛИ card_collection_button_link)
  // setCookiesAbTest('card_collection_button_link');



};

const eventContextMenu = (event: any) => {
  if (userManagerInfo.value?.id) {
    return false
  }

  const isImage = Boolean(event?.target?.localName === 'img');
  if (!isImage) {
    return null
  }

  const isChildrenSlider = event.target.closest('.swiper-slide');
  const isChildrenAccordion = event.target.closest('.accordion-gallery-block');
  const isChildrenItemMenu = event.target.closest('.item-menu-category');
  const isChildrenProject3d = event.target.closest('.project3d__content');
  if (!Boolean(isChildrenSlider || isChildrenAccordion || isChildrenItemMenu || isChildrenProject3d)) {
    return null
  }

  event.preventDefault();
  event.stopPropagation();
  return false
};

</script>

<style lang="scss">
.root-layout {
	display: flex;
	flex-direction: column;
}

.root-layout__body {
	min-height: calc(100vh - 180px);
}

.root-layout__footer {
	margin-top: 50px;
  &.mt-0 {
    margin-top: 0;
  }
}

@supports (min-height:100dvh) {
	.root-layout__body {
		min-height: calc(100dvh - 180px);
	}
}

@media (max-width: 1023px) {
	.root-layout__footer {
		margin-top: 40px;
	}
}
</style>
