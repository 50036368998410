import { default as _91_46_46_46cms_93GVVyMb9ADGMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/[...cms].vue?macro=true";
import { default as _91_46_46_46slug_93xE5MOtqUDxMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/3d-projects/[...slug].vue?macro=true";
import { default as index9MGtcJmggvMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/3d-projects/index.vue?macro=true";
import { default as _91_46_46_46slug_93PTAna9ySSXMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/akcii/[...slug].vue?macro=true";
import { default as indexnNkf9OJRDBMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/akcii/index.vue?macro=true";
import { default as _91_46_46_46slug_93sH7t2dDRoSMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/articles/[...slug].vue?macro=true";
import { default as indexNm9kBN2hmRMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/articles/index.vue?macro=true";
import { default as index6xFMsUmlJiMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/authorization/index.vue?macro=true";
import { default as indexUpcHWYfIUdMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/cart/index.vue?macro=true";
import { default as indexj1BfooMqPEMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/cart/success/index.vue?macro=true";
import { default as _91_46_46_46slug_93sHVrFJDe2MMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/catalog/[...slug].vue?macro=true";
import { default as indexuqYuncMWLbMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/catalog/index.vue?macro=true";
import { default as _91_46_46_46slug_93pr9i8iRFuJMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/catalogsearch/[...slug].vue?macro=true";
import { default as resultb3WSsyzU5QMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/catalogsearch/result.vue?macro=true";
import { default as indexNlEwnr8bSRMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/clear-page-slider/index.vue?macro=true";
import { default as _91_46_46_46slug_93h43yYC3kkvMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/collection/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93rIeyov9LnsMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/fabriki/[...slug].vue?macro=true";
import { default as indexpwoiUfIv44Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/fabriki/index.vue?macro=true";
import { default as _91_46_46_46slug_93wAe7KDSVIbMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/product/[...slug].vue?macro=true";
import { default as indexIkQcKasblfMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/profile/index.vue?macro=true";
import { default as indexglLdFb5qnAMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/profile/orders/index.vue?macro=true";
import { default as indexpgjIm9wN1uMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/recently/index.vue?macro=true";
import { default as _91_46_46_46slug_93ESHZW55cc9Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/sets/[...slug].vue?macro=true";
import { default as indexKV47achBaBMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/wishlist/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46cms_93GVVyMb9ADGMeta?.name ?? "cms",
    path: _91_46_46_46cms_93GVVyMb9ADGMeta?.path ?? "/:cms(.*)*",
    meta: _91_46_46_46cms_93GVVyMb9ADGMeta || {},
    alias: _91_46_46_46cms_93GVVyMb9ADGMeta?.alias || [],
    redirect: _91_46_46_46cms_93GVVyMb9ADGMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/[...cms].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93xE5MOtqUDxMeta?.name ?? "3d-projects-slug",
    path: _91_46_46_46slug_93xE5MOtqUDxMeta?.path ?? "/3d-projects/:slug(.*)*",
    meta: _91_46_46_46slug_93xE5MOtqUDxMeta || {},
    alias: _91_46_46_46slug_93xE5MOtqUDxMeta?.alias || [],
    redirect: _91_46_46_46slug_93xE5MOtqUDxMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/3d-projects/[...slug].vue").then(m => m.default || m)
  },
  {
    name: index9MGtcJmggvMeta?.name ?? "3d-projects",
    path: index9MGtcJmggvMeta?.path ?? "/3d-projects",
    meta: index9MGtcJmggvMeta || {},
    alias: index9MGtcJmggvMeta?.alias || [],
    redirect: index9MGtcJmggvMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/3d-projects/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93PTAna9ySSXMeta?.name ?? "akcii-slug",
    path: _91_46_46_46slug_93PTAna9ySSXMeta?.path ?? "/akcii/:slug(.*)*",
    meta: _91_46_46_46slug_93PTAna9ySSXMeta || {},
    alias: _91_46_46_46slug_93PTAna9ySSXMeta?.alias || [],
    redirect: _91_46_46_46slug_93PTAna9ySSXMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/akcii/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexnNkf9OJRDBMeta?.name ?? "akcii",
    path: indexnNkf9OJRDBMeta?.path ?? "/akcii",
    meta: indexnNkf9OJRDBMeta || {},
    alias: indexnNkf9OJRDBMeta?.alias || [],
    redirect: indexnNkf9OJRDBMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/akcii/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93sH7t2dDRoSMeta?.name ?? "articles-slug",
    path: _91_46_46_46slug_93sH7t2dDRoSMeta?.path ?? "/articles/:slug(.*)*",
    meta: _91_46_46_46slug_93sH7t2dDRoSMeta || {},
    alias: _91_46_46_46slug_93sH7t2dDRoSMeta?.alias || [],
    redirect: _91_46_46_46slug_93sH7t2dDRoSMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/articles/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexNm9kBN2hmRMeta?.name ?? "articles",
    path: indexNm9kBN2hmRMeta?.path ?? "/articles",
    meta: indexNm9kBN2hmRMeta || {},
    alias: indexNm9kBN2hmRMeta?.alias || [],
    redirect: indexNm9kBN2hmRMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: index6xFMsUmlJiMeta?.name ?? "authorization",
    path: index6xFMsUmlJiMeta?.path ?? "/authorization",
    meta: index6xFMsUmlJiMeta || {},
    alias: index6xFMsUmlJiMeta?.alias || [],
    redirect: index6xFMsUmlJiMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexUpcHWYfIUdMeta?.name ?? "cart",
    path: indexUpcHWYfIUdMeta?.path ?? "/cart",
    meta: indexUpcHWYfIUdMeta || {},
    alias: indexUpcHWYfIUdMeta?.alias || [],
    redirect: indexUpcHWYfIUdMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexj1BfooMqPEMeta?.name ?? "cart-success",
    path: indexj1BfooMqPEMeta?.path ?? "/cart/success",
    meta: indexj1BfooMqPEMeta || {},
    alias: indexj1BfooMqPEMeta?.alias || [],
    redirect: indexj1BfooMqPEMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/cart/success/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93sHVrFJDe2MMeta?.name ?? "catalog-slug",
    path: _91_46_46_46slug_93sHVrFJDe2MMeta?.path ?? "/catalog/:slug(.*)*",
    meta: _91_46_46_46slug_93sHVrFJDe2MMeta || {},
    alias: _91_46_46_46slug_93sHVrFJDe2MMeta?.alias || [],
    redirect: _91_46_46_46slug_93sHVrFJDe2MMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/catalog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexuqYuncMWLbMeta?.name ?? "catalog",
    path: indexuqYuncMWLbMeta?.path ?? "/catalog",
    meta: indexuqYuncMWLbMeta || {},
    alias: indexuqYuncMWLbMeta?.alias || [],
    redirect: indexuqYuncMWLbMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93pr9i8iRFuJMeta?.name ?? "catalogsearch-slug",
    path: _91_46_46_46slug_93pr9i8iRFuJMeta?.path ?? "/catalogsearch/:slug(.*)*",
    meta: _91_46_46_46slug_93pr9i8iRFuJMeta || {},
    alias: _91_46_46_46slug_93pr9i8iRFuJMeta?.alias || [],
    redirect: _91_46_46_46slug_93pr9i8iRFuJMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/catalogsearch/[...slug].vue").then(m => m.default || m)
  },
  {
    name: resultb3WSsyzU5QMeta?.name ?? "catalogsearch-result",
    path: resultb3WSsyzU5QMeta?.path ?? "/catalogsearch/result",
    meta: resultb3WSsyzU5QMeta || {},
    alias: resultb3WSsyzU5QMeta?.alias || [],
    redirect: resultb3WSsyzU5QMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/catalogsearch/result.vue").then(m => m.default || m)
  },
  {
    name: indexNlEwnr8bSRMeta?.name ?? "clear-page-slider",
    path: indexNlEwnr8bSRMeta?.path ?? "/clear-page-slider",
    meta: indexNlEwnr8bSRMeta || {},
    alias: indexNlEwnr8bSRMeta?.alias || [],
    redirect: indexNlEwnr8bSRMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/clear-page-slider/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93h43yYC3kkvMeta?.name ?? "collection-slug",
    path: _91_46_46_46slug_93h43yYC3kkvMeta?.path ?? "/collection/:slug(.*)*",
    meta: _91_46_46_46slug_93h43yYC3kkvMeta || {},
    alias: _91_46_46_46slug_93h43yYC3kkvMeta?.alias || [],
    redirect: _91_46_46_46slug_93h43yYC3kkvMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/collection/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93rIeyov9LnsMeta?.name ?? "fabriki-slug",
    path: _91_46_46_46slug_93rIeyov9LnsMeta?.path ?? "/fabriki/:slug(.*)*",
    meta: _91_46_46_46slug_93rIeyov9LnsMeta || {},
    alias: _91_46_46_46slug_93rIeyov9LnsMeta?.alias || [],
    redirect: _91_46_46_46slug_93rIeyov9LnsMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/fabriki/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexpwoiUfIv44Meta?.name ?? "fabriki",
    path: indexpwoiUfIv44Meta?.path ?? "/fabriki",
    meta: indexpwoiUfIv44Meta || {},
    alias: indexpwoiUfIv44Meta?.alias || [],
    redirect: indexpwoiUfIv44Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/fabriki/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93wAe7KDSVIbMeta?.name ?? "product-slug",
    path: _91_46_46_46slug_93wAe7KDSVIbMeta?.path ?? "/product/:slug(.*)*",
    meta: _91_46_46_46slug_93wAe7KDSVIbMeta || {},
    alias: _91_46_46_46slug_93wAe7KDSVIbMeta?.alias || [],
    redirect: _91_46_46_46slug_93wAe7KDSVIbMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/product/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexIkQcKasblfMeta?.name ?? "profile",
    path: indexIkQcKasblfMeta?.path ?? "/profile",
    meta: indexIkQcKasblfMeta || {},
    alias: indexIkQcKasblfMeta?.alias || [],
    redirect: indexIkQcKasblfMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexglLdFb5qnAMeta?.name ?? "profile-orders",
    path: indexglLdFb5qnAMeta?.path ?? "/profile/orders",
    meta: indexglLdFb5qnAMeta || {},
    alias: indexglLdFb5qnAMeta?.alias || [],
    redirect: indexglLdFb5qnAMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/profile/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexpgjIm9wN1uMeta?.name ?? "recently",
    path: indexpgjIm9wN1uMeta?.path ?? "/recently",
    meta: indexpgjIm9wN1uMeta || {},
    alias: indexpgjIm9wN1uMeta?.alias || [],
    redirect: indexpgjIm9wN1uMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/recently/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93ESHZW55cc9Meta?.name ?? "sets-slug",
    path: _91_46_46_46slug_93ESHZW55cc9Meta?.path ?? "/sets/:slug(.*)*",
    meta: _91_46_46_46slug_93ESHZW55cc9Meta || {},
    alias: _91_46_46_46slug_93ESHZW55cc9Meta?.alias || [],
    redirect: _91_46_46_46slug_93ESHZW55cc9Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/sets/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexKV47achBaBMeta?.name ?? "wishlist",
    path: indexKV47achBaBMeta?.path ?? "/wishlist",
    meta: indexKV47achBaBMeta || {},
    alias: indexKV47achBaBMeta?.alias || [],
    redirect: indexKV47achBaBMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241115133402/pages/wishlist/index.vue").then(m => m.default || m)
  }
]