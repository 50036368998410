import { defineStore } from 'pinia'

export const useCommonStore = defineStore({
  id: "common",

  state: () => {
    return {
      _loading: false,
      _headerFixed: false
    }
  },

  actions: {
    changeLoading: function (val: boolean) {
      this._loading = val;
    },
    changeHeaderFixed: function (val: boolean) {
      this._headerFixed = val;
    },
  }
});
